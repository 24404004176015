class HttpException extends Error {
  constructor(message, httpRequest = {}) {
    super(message)
    this.name = this.constructor.name
    this.requestId =
      httpRequest.requestId || httpRequest?.response?.headers?.http_x_request_id
    this.url = httpRequest.url || httpRequest?.response?.config?.url
    this.responseStatusCode =
      httpRequest.responseStatusCode || httpRequest?.response?.status
    this.body = httpRequest?.response?.data
    this.errorMessage = httpRequest?.message
  }
}

export default HttpException
