import type { AppProps } from 'next/app'
import '@juntossomosmais/atomium-tokens/tokens.css'
import '@juntossomosmais/atomium/core.css'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Component {...pageProps} />
    </>
  )
}

export default MyApp
