import * as Sentry from '@sentry/nextjs'
import { Integrations } from '@sentry/tracing'

import { ENV } from '~/enums/environments'
import { getEnv } from '~/helpers/getEnv'

import { FilterHttpEvent } from './integrations/http'

const ignoreErrors = [
  'Request failed with status code 400',
  'Request failed with status code 401',
  'Request failed with status code 403',
  'Request failed with status code 404',
  'Network Error',
  /* Probably it is a Sentry's bug on SDK and we
   * will remove the line bellow after they fix it
   * https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/14
   */
  'Non-Error exception captured',
  'Non-Error promise rejection captured',
]

const defineVisibilityAndRatio = () => {
  if (typeof window === 'undefined') return

  if (getEnv() === ENV.Prod) {
    return 0.01
  }

  return 0
}

export const initSentry = () => {
  const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN
  const authToken = process.env.NEXT_PUBLIC_SENTRY_AUTH_TOKEN

  if (!dsn || !authToken || typeof window === 'undefined') return
  Sentry.init({
    dsn,
    tracesSampleRate: defineVisibilityAndRatio(),
    integrations: [new Integrations.BrowserTracing(), new FilterHttpEvent()],
    ignoreErrors,
    environment: getEnv(),
  })
}
