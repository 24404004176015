export enum ENV {
  Prod = 'production',
  Hml = 'staging',
  Dev = 'development',
  Local = 'local',
  Test = 'test',
}

export enum ApiUrls {
  local = 'https://api-sites-loja.develop.juntossomosmaisi.com.br',
  development = 'https://api-sites-loja.develop.juntossomosmaisi.com.br',
  staging = 'https://api-sites-loja.homolog.juntossomosmaisi.com.br',
  production = 'https://api-sites-loja.juntossomosmais.com.br',
  oldProduction = 'https://api-sites-loja.juntossomosmais.com.br',
  Test = 'fakeAPI',
}

export enum Urls {
  local = 'https://portaladmin.develop.juntossomosmaisi.com.br',
  development = 'https://portaladmin.develop.juntossomosmaisi.com.br',
  staging = 'https://portaladmin.homolog.juntossomosmaisi.com.br',
  production = 'https://portaladmin.juntossomosmais.com.br',
}
