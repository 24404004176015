import { Event, EventHint, EventProcessor, Integration } from '@sentry/types'

import HttpException from '~/support/exceptions/HttpException'

export class FilterHttpEvent implements Integration {
  /**
   * @inheritDoc
   */
  public static id = 'FilterHttpEvent'

  /**
   * @inheritDoc
   */
  public name = FilterHttpEvent.id

  private readonly httpStatus = [400, 401, 403, 404]

  createFilterHttpEvent(event: Event, hint: EventHint) {
    const originalException = hint.originalException
    const isHttpException = originalException instanceof HttpException
    const hasNetworkError =
      isHttpException && originalException.errorMessage === 'Network Error'
    const hasResponseStatusMatch =
      isHttpException &&
      this.httpStatus.includes(originalException.responseStatusCode)

    if (hasNetworkError || hasResponseStatusMatch) return null

    return event
  }

  setupOnce(addGlobalEventProcessor: (callback: EventProcessor) => void) {
    addGlobalEventProcessor(this.createFilterHttpEvent.bind(this))
  }
}
